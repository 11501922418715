import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PortfolioView from '../views/PortfolioView.vue'
import MainSection from '@/sections/MainSection.vue'
import AboutSection from '@/sections/AboutSection.vue'
import ServiceSection from '@/sections/ServiceSection.vue'
import PortfolioSection from '@/sections/PortfolioSection.vue'
import ContactSection from '@/sections/ContactSection.vue'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/PortfolioView',
      name: 'portfolio',
      component: PortfolioView
    },
    {
      path: '/main',
      name: 'main-section',
      component: MainSection
    },
    {
      path: '/about',
      name: 'about-section',
      component: AboutSection
    },
    {
      path: '/services',
      name: 'services-section',
      component: ServiceSection
    },
    {
      path: '/portfolio',
      name: 'portfolio-section',
      component: PortfolioSection
    },
    {
      path: '/contact',
      name: 'contact-section',
      component: ContactSection
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

export default router
