<template>
    <!-- Contact section start -->
    <section id="contact-section" class="px-12 lg:px-32 pt-32 pb-16 relative">
        <!-- Main text start -->
        <h1 class="uppercase text-5xl mb-4 font-semibold ">
            ติดต่อเรา
        </h1>
        <!-- Main text end -->

        <!-- Form start -->
        <div class="grid grid-cols-1 md:grid-cols-2 w-full">
            <div class="hidden md:flex justify-center items-center">
                <img src="@/assets/profile-logo.png" alt="">
            </div>
            <div class="flex flex-col w-full items-center  bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <div
                    class="w-full p-4 text-center">
                    <h5 class="mb-2 text-2xl md:text-3xl font-bold text-gray-900 dark:text-white"> บริหารงานโดย <br/> ส.ต.ท.นครินทร์ ใจสุข </h5>
                    <p class="capitalize text-1xl md:text-2xl text-stone-500">
                        ให้บริการรถตู้เช่า/เหมา ท่องเที่ยว สัมมนา ทัศนาจร รับ-ส่ง หน้าด่าน-สนามบิน
                    </p>

                    <div class="flex mt-3 mb-3 gap-3 md:flex-row flex-col justify-evenly">


                        <a href="https://line.me/ti/p/U7DEIOLwgZ" target="_blank"
                            class="justify-start w-full sm:w-auto bg-green-600 hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center px-4 py-3.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                            <!-- <i class="fa-brands fa-line socialIcon me-3 w-7 h-7" style="color: #fff;"></i> -->
                            <font-awesome-icon :icon="['fab', 'line']"  class="socialIcon me-3 w-7 h-7  text-white"/>
                            <div class="text-left ml-4 rtl:text-right">
                                <div class="mb-1  text-white text-xs animate-blink ">คลิ๊กที่นี่ <i class="fas fa-rss text-red-700"></i></div>
                                <div class="-mt-1  text-white font-sans text-sm font-semibold">ID : sansab_krab</div>
                            </div>
                        </a>

                        <a href="https://www.facebook.com/nongkhaivanbypumoo" target="_blank"
                            class="w-full sm:w-auto bg-blue-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-start px-4 py-3.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                            <!-- <i class="fa-brands fa-facebook socialIcon" style="color: #fff;"></i> -->
                            <font-awesome-icon :icon="['fab', 'facebook']"  class="socialIcon me-3 w-7 h-7  text-white"/>
                            <div class="text-left ml-4 rtl:text-right">
                                <div class="mb-1  text-white text-xs animate-blink">คลิ๊กที่นี่ <i class="fas fa-rss text-red-700"></i></div>
                                <div class="-mt-1  text-white font-sans text-sm font-semibold">รถตู้หนองคายbyผู้หมู่ภูธร
                                </div>
                            </div>
                        </a>

                        <a href="tel:085-461-8478" target="_blank"
                            class="w-full sm:w-auto bg-yellow-600 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-gray-300 text-white rounded-lg inline-flex items-center justify-start px-4 py-3.5 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                            <!-- <i class="fa-solid fa-square-phone socialIcon" style="color: #fff;"></i> -->
                            <font-awesome-icon :icon="['fas', 'phone']" class="socialIcon me-3 w-7 h-7  text-white"/>
                            <div class="text-left ml-4 rtl:text-right">
                                <div class="mb-1  text-white text-xs animate-blink">คลิ๊กที่นี่ <i class="fas fa-rss text-red-700"></i></div>
                                <div class="-mt-1  text-white font-sans text-sm font-semibold">โทร : 085-461-8478</div>
                            </div>
                        </a>

                    </div>
                </div>

                <img class="items-center" src="@/assets/qrline.png" alt="">

                    <p class="mb-5 text-base text-center text-gray-500 sm:text-lg dark:text-gray-400">
                        ขอขอบคุณที่ไว้วางใจให้ทีมงานของเราได้ดูแลคุณ 
                    </p>



            </div>
        </div>

        <!-- Form end -->

        <!-- Circle start -->
        <div class="bg-neutral-200 h-44 w-44 md:h-52 md:w-52 rounded-full absolute -top-20 left-0 mt-16 -z-20"></div>
        <!-- Circle end -->
    </section>
    <!-- Contact section end -->
</template>

<script>
export default {

}
</script>

<style>
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.animate-blink {
    animation: blink 1s infinite;
}

</style>