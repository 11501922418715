<template>
  <section id="portfolioview-section" class="px-4 lg:px-32 pt-32 pb-16 relative">
    <h1 class="uppercase text-5xl mb-4 font-semibold">
      แกลลอรี่
    </h1>
    <p class="capitalize xl:w-4/5 text-2xl text-stone-500 mb-8">
      ประมวลภาพการให้บริการรถตู้เช่า/เหมา ท่องเที่ยว สัมมนา ทัศนาจร รับ-ส่ง หน้าด่าน-สนามบิน จากทีมงาน ผู้หมู่ภูธร
    </p>

    <!-- <div v-if="loading" class="text-center">
      <div class="text-center">
        <span class="loader">Load&nbsp;ng</span>
      </div>
    </div> -->

    <Loading v-if="loading" />


    <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
      <template v-for="(group, index) in currentGroups" :key="index">
        <div v-if="Array.isArray(group) && group.length" class="grid gap-4">
          <div v-for="photo in group" :key="photo.id" class="photo-item">
            <figure class="relative transition-all duration-300 cursor-pointer">
              <img class="h-auto max-w-full rounded-lg" :src="photo.picture" :alt="photo.name"
                @click="openModal(photo)">
            </figure>
          </div>
        </div>
      </template>
    </div>

    <div class="mt-4 py-2 text-center rounded">
      <button v-if="!loading && hasMore" @click="loadMore"
        class="text-gray-900 hover:text-white border border-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800">
        <p class="font-semibold text-lg group relative ">
          <span>โหลดเพิ่มเติม </span>
          <i class="fa-solid fa-arrow-down"></i>
        </p>
      </button>
    </div>

    <Modal v-if="selectedPhoto" :photo="selectedPhoto" @close="closeModal" />
  </section>
</template>

<script>
import { ref, computed } from 'vue';
import axios from 'axios';
import Modal from '@/components/ModalImg.vue'; // นำเข้า Modal
import Loading from '@/components/LoadingComponent.vue'; // นำเข้า Loading

export default {
  components: {
    Modal,
    Loading, // ลงทะเบียน Component
  },
  setup() {
    const photos = ref([]); // ใช้งาน composition API
    const selectedPhoto = ref(null);
    const itemsPerLoad = 8; // จำนวนรูปภาพที่โหลดในแต่ละครั้ง
    const currentCount = ref(0); // จำนวนรูปภาพที่แสดงในปัจจุบัน
    const loading = ref(true); // เพิ่มตัวแปร loading
    const nextPageUrl = ref(null); // URL สำหรับหน้าถัดไป
    const hasMore = ref(true); // สถานะว่ามีหน้าต่อไปไหม

    // ฟังก์ชันเพื่อดึงข้อมูลรูปภาพ
    const fetchAlbumPhotos = async (url = null) => {
      const accessToken = process.env.VUE_APP_FB_ACCESS_TOKEN;
      const albumId = process.env.VUE_APP_FB_ALBUM_ID; // ใส่ ID ของอัลบั้มที่ต้องการดึงรูปภาพ 
      const apiUrl = url || `https://graph.facebook.com/v19.0/${albumId}/photos?fields=id,name,images&access_token=${accessToken}`;

      try {
        loading.value = true; // ตั้งค่า loading เป็น true ก่อนเริ่มโหลด
        const response = await axios.get(apiUrl);

        // ดึงรูปภาพที่มีความละเอียดสูงสุด
        const newPhotos = response.data.data.map(photo => {
          const highestResImage = photo.images.sort((a, b) => b.width - a.width)[0];
          return {
            id: photo.id,
            name: photo.name,
            picture: highestResImage.source,
          };
        });

        photos.value.push(...newPhotos); // เพิ่มรูปภาพใหม่ลงใน photos
        currentCount.value = Math.min(currentCount.value + itemsPerLoad, photos.value.length);
        nextPageUrl.value = response.data.paging?.next || null; // ตรวจสอบ URL ของหน้าถัดไป
        hasMore.value = !!nextPageUrl.value; // อัปเดตสถานะว่าใช่หรือไม่ว่ามีหน้าถัดไป
      } catch (error) {
        console.error('Error fetching photos:', error);
      } finally {
        loading.value = false; // ตั้งค่า loading เป็น false หลังจากโหลดเสร็จ
      }
    };

    // เรียกใช้ฟังก์ชันเพื่อดึงข้อมูลเมื่อ component ถูกสร้าง
    fetchAlbumPhotos();

    // ฟังก์ชันเพื่อเปิดและปิด modal
    const openModal = (photo) => {
      selectedPhoto.value = photo;
    };

    const closeModal = () => {
      selectedPhoto.value = null;
    };

    // คำนวณกลุ่มรูปภาพที่จะแสดงในปัจจุบัน
    const currentGroups = computed(() => {
      const groups = [];
      if (!photos.value || !Array.isArray(photos.value) || photos.value.length === 0) return groups; // เพิ่มการตรวจสอบ
      for (let i = 0; i < currentCount.value; i += 2) {
        groups.push(photos.value.slice(i, i + 2));
      }
      return groups;
    });

    // ฟังก์ชันเพื่อโหลดหน้าถัดไป
    const loadMore = () => {
      if (nextPageUrl.value) {
        fetchAlbumPhotos(nextPageUrl.value);
      }
    };

    // เริ่มต้นการแสดงรูปภาพ
    currentCount.value = itemsPerLoad;

    return {
      photos,
      selectedPhoto,
      openModal,
      closeModal,
      currentGroups,
      loadMore,
      currentCount,
      loading,
      hasMore,
    };
  },
};
</script>

<style>
.loader {
  color: #3f3f3f;
  display: inline-block;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
  letter-spacing: 4px;
  box-sizing: border-box;
}

.loader::before {
  content: '';
  position: absolute;
  right: 68px;
  bottom: 10px;
  height: 24px;
  width: 7px;
  outline: 1px solid #3f3f3f;
  color: #FF3D00;
  box-sizing: border-box;
  animation: animloader1 1s linear infinite alternate;
}

.loader::after {
  content: '';
  width: 4px;
  height: 4px;
  background: #FF3D00;
  position: absolute;
  right: 70px;
  top: 8px;
  box-sizing: border-box;
  animation: animloader 1s ease-out infinite alternate;
}

.loading-container {
  display: flex;
  justify-content: center;
  /* จัดกลางแนวนอน */
  align-items: center;
  /* จัดกลางแนวตั้ง */
  height: 100vh;
  /* ความสูงเต็มหน้าจอ */
  position: fixed;
  /* ให้มันอยู่บนสุด */
  top: 0;
  left: 0;
  width: 100%;
  /* ความกว้างเต็ม */
  background-color: rgba(255, 255, 255, 0.8);
  /* สีพื้นหลังที่อ่อนเพื่อให้ดูนุ่มนวล */
  z-index: 999;
  /* อยู่เหนือเนื้อหาอื่น */
}


@keyframes animloader {
  0% {
    top: 8px;
    transform: rotate(0deg) scale(1);
  }

  100% {
    top: 0px;
    transform: rotate(180deg) scale(1.5);
  }
}

@keyframes animloader1 {
  0% {
    box-shadow: 0 0 inset;
  }

  100% {
    box-shadow: 0 -28px inset;
  }
}
</style>