<template>
    <!-- Portfolio section start -->
    <section id="portfolio-section" class="px-4 lg:px-32 pt-32 pb-16 relative">
        <h1 class="uppercase text-5xl mb-4 font-semibold">
            แกลลอรี่
        </h1>
        <p class="capitalize xl:w-4/5 text-2xl text-stone-500 mb-8">
            ประมวลภาพการให้บริการรถตู้เช่า/เหมา ท่องเที่ยว สัมมนา ทัศนาจร รับ-ส่ง หน้าด่าน-สนามบิน จากทีมงาน ผู้หมู่ภูธร
        </p>

        <!-- Loading component -->
        <Loading v-if="loading" />

        <!-- Images grid start -->
        <div class="grid-cols-2 md:grid-cols-4 grid mb-8 gap-4">
            <div class="grid grid-cols-1 md:hidden gap-4">
                <img v-for="photo in photos.slice(0, 5)" :key="photo.id" :src="photo.picture" :alt="photo.name"
                    class="rounded-xl" @click="openModal(photo)" />
            </div>
            <div class="grid grid-cols-1 md:hidden gap-4">
                <img v-for="photo in photos.slice(6, 10)" :key="photo.id" :src="photo.picture" :alt="photo.name"
                    class="rounded-xl" @click="openModal(photo)" />
            </div>
            <div class="hidden md:flex flex-col gap-4">
                <img v-for="photo in photos.slice(0, 2)" :key="photo.id" :src="photo.picture" :alt="photo.name"
                    class="rounded-xl" @click="openModal(photo)" />
            </div>
            <div class="hidden md:flex flex-col gap-4">
                <img v-for="photo in photos.slice(2, 4)" :key="photo.id" :src="photo.picture" :alt="photo.name"
                    class="rounded-xl" @click="openModal(photo)" />
            </div>
            <div class="hidden md:flex flex-col gap-4">
                <img v-for="photo in photos.slice(4, 6)" :key="photo.id" :src="photo.picture" :alt="photo.name"
                    class="rounded-xl" @click="openModal(photo)" />
            </div>
            <div class="hidden md:flex flex-col gap-4">
                <img v-for="photo in photos.slice(6, 8)" :key="photo.id" :src="photo.picture" :alt="photo.name"
                    class="rounded-xl" @click="openModal(photo)" />
            </div>
        </div>
        <!-- Images grid end -->

        <!-- "More" link start -->
        <RouterLink to="/PortfolioView" class="text-end">
            <p class="font-semibold text-lg group relative">
                <span>ดูรูปภาพเพิ่มเติม </span>
                <i class="fa-solid fa-arrow-right"></i>
            </p>
        </RouterLink>
        <!-- "More" link end -->

        <!-- Circle start -->
        <div class="bg-neutral-300 h-64 w-64 rounded-full absolute top-0 left-0 mt-16 -z-20"></div>
        <!-- Circle end -->

        <!-- Modal for selected photo -->
        <Modal v-if="selectedPhoto" :photo="selectedPhoto" @close="closeModal" />
    </section>
    <!-- Portfolio section end -->
</template>

<script>
import axios from 'axios';
import Modal from '@/components/ModalImg.vue'; // นำเข้า Modal Component
import Loading from '@/components/LoadingComponent.vue'; // นำเข้า Loading

export default {
    components: {
        Modal, // ลงทะเบียน Component
        Loading,
    },
    data() {
        return {
            photos: [],
            selectedPhoto: null, // เก็บข้อมูลของรูปภาพที่ถูกเลือก
            loading: true, // เพิ่มตัวแปร loading
        };
    },
    created() {
        this.fetchAlbumPhotos();
    },
    methods: {
        async fetchAlbumPhotos(url = null) { // ปรับฟังก์ชันให้เป็นแบบปกติ
            const accessToken = process.env.VUE_APP_FB_ACCESS_TOKEN;
            const albumId = process.env.VUE_APP_FB_ALBUM_ID; // ใส่ ID ของอัลบั้มที่ต้องการดึงรูปภาพ 
            const apiUrl = url || `https://graph.facebook.com/v19.0/${albumId}/photos?fields=id,name,images&access_token=${accessToken}`;

            try {
                this.loading = true; // ตั้งค่า loading เป็น true ก่อนเริ่มโหลด
                const photosResponse = await axios.get(apiUrl);

                this.photos = photosResponse.data.data.map(photo => {
                    const highestResImage = photo.images.sort((a, b) => b.width - a.width)[0];
                    return {
                        id: photo.id,
                        name: photo.name,
                        picture: highestResImage.source,
                    };
                });
            } catch (error) {
                console.error('Error fetching album photos:', error);
            } finally {
                this.loading = false; // ตั้งค่า loading เป็น false หลังจากโหลดเสร็จ
            }
        },
        openModal(photo) {
            this.selectedPhoto = photo; // เปิด Modal เมื่อคลิกที่รูป
        },
        closeModal() {
            this.selectedPhoto = null; // ปิด Modal
        },
    },

};
</script>
