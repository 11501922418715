<style>
@media (min-width: 768px) {
    div#slider {
        height: 40rem;
    }
}
</style>
<template>
    <!-- About section start -->
    <section id="about-section" class="px-12 lg:px-32 pt-32 pb-16 border-t border-stone-500 relative">
        <!-- Main text start -->
        <h1 class="uppercase text-5xl mb-4 font-semibold ">
            เกี่ยวกับเรา
        </h1>
        <p class="capitalize xl:w-4/5 text-2xl text-stone-500 mb-8" style="text-indent: 2.5em;">
            เราบริการรถตู้ เพื่อการท่องเที่ยวและการเดินทาง จากสนามบินไปยังสถานที่ต่าง ๆ ในประเทศ <br />
            เรามีทีมงานที่ประสบการณ์ และมีความชำนาญในการให้บริการ
            คนขับรถตู้ทุกคนในทีมเรามุ่งมั่นที่จะให้บริการที่ดีที่สุดแก่ลูกค้าของเรา
        </p>
        <!-- Main text end -->


        <div id="carousel-example" class="relative w-full">
            <!-- Carousel wrapper -->
            <div id="slider" class="relative overflow-hidden h-64 rounded-lg sm:h-96">
                <!-- Item 1 -->
                <div id="carousel-item-1" class=" duration-700 ease-in-out">
                    <img src="@/assets/portfolio/portfolio1.jpg"
                        class="absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2" alt="..." />
                </div>
                <!-- Item 2 -->
                <div id="carousel-item-2" class="duration-700 ease-in-out">
                    <img src="@/assets/portfolio/portfolio2.jpg"
                        class="absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2" alt="..." />
                </div>
                <!-- Item 3 -->
                <div id="carousel-item-3" class="duration-700 ease-in-out">
                    <img src="@/assets/portfolio/portfolio3.jpg"
                        class="absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2" alt="..." />
                </div>
                <!-- Item 4 -->
                <div id="carousel-item-4" class="duration-700 ease-in-out">
                    <img src="@/assets/portfolio/portfolio4.jpg"
                        class="absolute left-1/2 top-1/2 block w-full -translate-x-1/2 -translate-y-1/2" alt="..." />
                </div>
            </div>
            <!-- Slider indicators -->
            <div class="absolute bottom-5 left-1/2 z-30 flex -translate-x-1/2 space-x-3 rtl:space-x-reverse">
                <button id="carousel-indicator-1" type="button" class="h-3 w-3 rounded-full" aria-current="true"
                    aria-label="Slide 1"></button>
                <button id="carousel-indicator-2" type="button" class="h-3 w-3 rounded-full" aria-current="false"
                    aria-label="Slide 2"></button>
                <button id="carousel-indicator-3" type="button" class="h-3 w-3 rounded-full" aria-current="false"
                    aria-label="Slide 3"></button>
                <button id="carousel-indicator-4" type="button" class="h-3 w-3 rounded-full" aria-current="false"
                    aria-label="Slide 4"></button>
            </div>
            <!-- Slider controls -->
            <button id="data-carousel-prev" type="button"
                class="group absolute left-0 top-0 z-30 flex h-full cursor-pointer items-center justify-center px-4 focus:outline-none">
                <span
                    class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70">
                    <svg class="h-4 w-4 text-white dark:text-gray-800" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M5 1 1 5l4 4" />
                    </svg>
                    <span class="hidden">Previous</span>
                </span>
            </button>
            <button id="data-carousel-next" type="button"
                class="group absolute right-0 top-0 z-30 flex h-full cursor-pointer items-center justify-center px-4 focus:outline-none">
                <span
                    class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-white/30 group-hover:bg-white/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-gray-800/30 dark:group-hover:bg-gray-800/60 dark:group-focus:ring-gray-800/70">
                    <svg class="h-4 w-4 text-white dark:text-gray-800" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 9 4-4-4-4" />
                    </svg>
                    <span class="hidden">Next</span>
                </span>
            </button>
        </div>



        <!-- "More" link start -->
        <!-- <a href="#" class="text-end">
            <p class="font-semibold text-lg group relative">
                <span>Read more </span>
                <i class="fa-solid fa-arrow-right"></i>
            </p>
        </a> -->
        <!-- "More" link end -->

        <!-- Circle start -->
        <div class="bg-neutral-200 h-44 w-44 md:h-52 md:w-52 rounded-full absolute top-0 -left-20 mt-16 -z-20"></div>
        <!-- Circle end -->
    </section>
    <!-- About section end -->
</template>

<script>
import { Carousel } from 'flowbite'; // import Carousel จาก flowbite module

export default {
    mounted() {
        // กำหนดตัวแปร carouselElement เพื่อใช้ในการสร้าง instance ของ Carousel
        const carouselElement = document.getElementById('carousel-example');

        // ตรวจสอบว่า carouselElement ไม่เป็น null ก่อนที่จะดำเนินการต่อ
        if (carouselElement !== null) {
            // กำหนด items ซึ่งเป็นรายการของสิ่งที่จะแสดงใน Carousel
            const items = [
                {
                    position: 0,
                    el: document.getElementById('carousel-item-1'),
                },
                {
                    position: 1,
                    el: document.getElementById('carousel-item-2'),
                },
                {
                    position: 2,
                    el: document.getElementById('carousel-item-3'),
                },
                {
                    position: 3,
                    el: document.getElementById('carousel-item-4'),
                },
            ];

            // กำหนด options ของ Carousel
            const options = {
                defaultPosition: 1,
                interval: 3000,

                indicators: {
                    activeClasses: 'bg-white dark:bg-gray-800',
                    inactiveClasses:
                        'bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800',
                    items: [
                        {
                            position: 0,
                            el: document.getElementById('carousel-indicator-1'),
                        },
                        {
                            position: 1,
                            el: document.getElementById('carousel-indicator-2'),
                        },
                        {
                            position: 2,
                            el: document.getElementById('carousel-indicator-3'),
                        },
                        {
                            position: 3,
                            el: document.getElementById('carousel-indicator-4'),
                        },
                    ],
                },

                // callback functions
                onNext: () => {
                },
                onPrev: () => {
                },
                onChange: () => {
                },
            };
            // กำหนด instanceOptions ซึ่งเป็นตัวเลือกของการสร้าง instance ของ Carousel
            const instanceOptions = {
                id: 'carousel-example',
                override: true
            };

            // สร้าง instance ของ Carousel โดยใช้ตัวแปรที่กำหนดไว้
            const carousel = new Carousel(carouselElement, items, options, instanceOptions);
            carousel.cycle();
            // กำหนดการดักฟังการคลิกที่ปุ่ม prevButton เพื่อเลื่อน Carousel ไปทางซ้าย
            const $prevButton = document.getElementById('data-carousel-prev');
            if ($prevButton !== null) {
                $prevButton.addEventListener('click', () => {
                    carousel.prev();
                });
            }

            // กำหนดการดักฟังการคลิกที่ปุ่ม nextButton เพื่อเลื่อน Carousel ไปทางขวา
            const $nextButton = document.getElementById('data-carousel-next');
            if ($nextButton !== null) {
                $nextButton.addEventListener('click', () => {
                    carousel.next();
                });
            }
        }
    }
};
</script>
