<template>
  <!-- Main section start -->
  <section id="main-section" class="h-screen px-12 lg:px-32 pt-32 pb-16 flex flex-col md:flex-row justify-between">
    <div class="w-full h-full flex flex-col md:justify-center">
      <!-- Main text start -->
      <h1 class="uppercase text-5xl xl:text-7xl mb-4 font-semibold">รถตู้หนองคาย</h1>
      <h1 class="uppercase text-3xl xl:text-4xl mb-4 font-semibold">By ผู้หมู่ภูธร</h1>
      <p class="capitalize xl:w-1/2 text-stone-500">
        ให้บริการรถตู้เช่า/เหมา ท่องเที่ยว สัมมนา ทัศนาจร รับ-ส่ง หน้าด่าน-สนามบิน
        <br />
        ในพื้นที่ภาคอีสานตอนบน จังหวัดหนองคาย บึงกาฬ อุดรธานี ขอนแก่น สกลนคร นครพนม และจังหวัดใกล้เคียง
        <br />
        <small class="hidden">รถตู้, เช่ารถตู้, เช่ารถตู้สนามบิน, บริการรับ-ส่ง, ทัศนาจร, สัมมนา, การเดินทาง, รถตู้หนองคาย, รถตู้อุดร, รถตู้บึงกาฬ, รถตู้เช่าสนามบิน,
          เช่ารถตู้เที่ยวสนามบิน, บริการรับ-ส่งสนามบิน, รถตู้เช่าสนามบินใกล้ฉัน, บริการรับ-ส่งที่สนามบิน, รถตู้เช่าสำหรับการท่องเที่ยวจากสนามบิน, บริการรถตู้สนามบิน,
          รถตู้เช่าสำหรับการเดินทางจากสนามบิน, เช่ารถตู้สำหรับการท่องเที่ยว, หนองคาย, บึงกาฬ, อุดรธานี, ขอนแก่น, สกลนคร, นครพนม</small>
      </p>
      <!-- Main text end -->

      <!-- Social links start -->
      <div class="flex mt-3 gap-4 md:flex-row flex-col">
        <a href="https://line.me/ti/p/U7DEIOLwgZ" target="_blank">
			<font-awesome-icon :icon="['fab', 'line']" /> : sansab_krab
        </a>
        <a href="https://www.facebook.com/nongkhaivanbypumoo" target="_blank">
			<font-awesome-icon :icon="['fab', 'facebook']" /> : รถตู้หนองคายbyผู้หมู่ภูธร
        </a>
        <a href="tel:085-461-8478">
			<font-awesome-icon :icon="['fas', 'phone']" /> : 085-461-8478
        </a>
      </div>
      <!-- Social links end -->
    </div>
    <!-- Photographer image start -->
    <div class="w-full h-full flex flex-col md:justify-center">
      <img src="@/assets/vans.png" alt="img" class="h-auto">
    </div>
    <!-- Photographer image end -->
  </section>
  <!-- Main section end -->
</template>

<script setup lang="js">

</script>

<style>
#main-section {
  background-image: url('/src/assets/bg.svg');
  background-size: cover;
}
</style>
