<script setup>
    import { RouterLink } from 'vue-router'
</script>
<template>
	<!-- Navbar start -->
	<nav class="w-screen fixed bg-opacity-90 bg-white p-4 lg:px-20 flex justify-between z-50">
		<!-- Logo start -->
		<div class="h-full flex flex-col justify-center">
			<RouterLink to="/" class="flex items-center space-x-3 rtl:space-x-reverse">
				<img src="@/assets/profile-logo.png" class="h-12" alt="Flowbite Logo" />
				<div class="row">
					<p class="tracking-widest font-semibold">รถตู้หนองคาย</p>
					<p class="tracking-widest font-light">By ผู้หมู่ภูธร</p>
				</div>
			</RouterLink>
		</div>
		<!-- Logo end -->

		<!-- Toggler start -->
		<button role="menubar" class="md:hidden" @click="navToggle()">
			<i class="fa-solid fa-bars text-2xl"></i>
		</button>
		<!-- Toggler end -->

		<!-- Menu content start -->
		<div class="absolute left-0 right-0 translate-y-16 bg-opacity-90 bg-white md:bg-none md:bg-opacity-0 shadow hidden md:flex flex-col gap-4 items-center p-4 md:flex-row md:static md:shadow-none md:translate-y-0"
			role="menu" aria-expanded="false">
			<!-- Links start -->
			<RouterLink to="/" role="menuitem" @click="navToggle()">
				<p class="mx-4">หน้าแรก</p>
			</RouterLink>

			<RouterLink to="/about" role="menuitem" @click="navToggle()">
				<p class="mx-4">เกี่ยวกับเรา</p>
			</RouterLink>

			<RouterLink to="services" role="menuitem" @click="navToggle()">
				<p class="mx-4">ข้อมูลบริการ</p>
			</RouterLink>

			<RouterLink to="portfolio" role="menuitem" @click="navToggle()">
				<p class="mx-4">แกลลอรี่</p>
			</RouterLink>

			<RouterLink to="contact" role="menuitem" @click="navToggle()">
				<p class="mx-4 btn">ติดต่อเรา</p>
			</RouterLink>

			
			<!-- Links end -->
		</div>
		<!-- Menu content end -->
	</nav>
	<!-- Navbar end -->
</template>

<script>
export default {
	methods: {
		// Toogle navbar mode
		navToggle() {
			let menu = document.querySelector("[role='menu']");
			let isExpanded = menu.getAttribute("aria-expanded");
			menu.setAttribute("aria-expanded", !isExpanded);
			menu.classList.toggle("hidden");
			menu.classList.toggle("flex");
		},
	},
};
</script>

<style></style>
