<template>
    <!-- Services section start -->
    <section id="services-section" class="px-12 lg:px-32 pt-32 pb-16 relative overflow-hidden">
        
            <!-- Main text start -->
            <h1 class="uppercase text-5xl mb-4 font-semibold ">
                ข้อมูลบริการ
            </h1>
            <div class="capitalize xl:w-4/5 text-sm md:text-2xl  text-stone-500 mb-8">
                <h1>บริการเช่ารถตู้พร้อมคนขับ</h1>
                <p>ราคาเริ่มต้น: 1️⃣8️⃣0️⃣0️⃣➖2️⃣0️⃣0️⃣0️⃣ บาท ต่อวัน/12ชั่วโมง</p>
                <p>(ไม่รวมค่าน้ำมันและค่าทางด่วน และค่าอื่นๆ เช่น ค่าที่จอดรถ ค่าเข้าอุทยาน ฯลฯ)</p>
                <p>ส่วนเกินคิดเป็น OT ชั่วโมงละ 2️⃣0️⃣0️⃣ บาท</p>
                <p>กรณีค้างคืนมีค่าที่พักคนขับ 5️⃣0️⃣0️⃣ บาท/คืน</p>
                <p>พร้อมวิ่งทั่วประเทศไทย ทริป งานบุญ ท่องเที่ยว งานขาวดำ งานสัมมนา หรือทริปต่างๆ</p>
            </div>
            <!-- Main text end -->
    

        <!-- Services start -->
        <div class="flex flex-col md:flex-row md:gap-8">
            <!-- Wedding service start -->
            <div class="flex flex-row md:flex-col justify-between md:justify-start items-center md:items-start my-8 md:w-1/3">
                <img src="@/assets/services/seat.png" alt="seat" class="-z-50 md:mb-4">
                <div class="w-2/3 text-right md:text-left md:w-full">
                    <h1 class="uppercase text-2xl font-semibold">
                        ที่นั่ง VIP
                    </h1>
                    <p class="capitalize text-stone-500">
                        ที่นั่ง VIP 10 ที่นั่ง เบาะ 3 แถว
                    </p>
                </div>
            </div>
            <!-- Wedding service end -->

            <!-- Wedding service start -->
            <div class="flex flex-row md:flex-col justify-between items-center md:items-start my-8 md:w-1/3">
                <div class="w-2/3 text-left md:w-full md:order-2">
                    <h1 class="uppercase text-2xl font-semibold">
                        เครื่องเสียง
                    </h1>
                    <p class="capitalize text-stone-500">
                        ระบบเครื่องเสียงที่มีคุณภาพ
                    </p>
                </div>
                <img src="@/assets/services/speakers.png" alt="speakers" class="-z-50 md:mb-4 md:order-1">
            </div>
            <!-- Wedding service end -->

            <!-- Wedding service start -->
            <div class="flex flex-row md:flex-col justify-between items-center md:items-start my-8 md:w-1/3">
                <div class="w-1/3">
                    <img src="@/assets/services/film.png" alt="film" class="-z-50 md:mb-4">
                </div>
                <div class="w-2/3 text-right md:text-left md:w-full">
                    <h1 class="uppercase text-2xl font-semibold">
                        ดูหนัง
                    </h1>
                    <p class="capitalize text-stone-500">
                        ดู youtube หรือ netflix ได้
                    </p>
                </div>
            </div>
            <!-- Wedding service end -->

        </div>

        <div class="flex flex-col md:flex-row md:gap-8">
            <!-- Wedding service start -->
            <div class="flex flex-row md:flex-col justify-between items-center md:items-start my-8 md:w-1/3">
                <div class="w-2/3 text-left md:w-full md:order-2">
                    <h1 class="uppercase text-2xl font-semibold">
                        ฟังเพลง
                    </h1>
                    <p class="capitalize text-stone-500">
                        เลือกเพลงแนวที่ชอบฟังได้
                    </p>
                </div>
                <img src="@/assets/services/music.png" alt="music" class="-z-50 md:mb-4 md:order-1">
            </div>
            <!-- Wedding service end -->

            <!-- Wedding service start -->
            <div class="flex flex-row md:flex-col justify-between items-center md:items-start my-8 md:w-1/3">
                <div class="w-1/3">
                    <img src="@/assets/services/microphone.png" alt="microphone" class="-z-50 md:mb-4">
                </div>
                <div class="w-2/3 text-right md:text-left md:w-full">
                    <h1 class="uppercase text-2xl font-semibold">
                        คาราโอเกะ
                    </h1>
                    <p class="capitalize text-stone-500">
                        มีไมโครโฟน ให้ร้องเพลง
                    </p>
                </div>
            </div>
            <!-- Wedding service end -->

            <!-- Wedding service start -->
            <div class="flex flex-row md:flex-col justify-between items-center md:items-start my-8 md:w-1/3">
                <div class="w-2/3 text-left md:w-full md:order-2">
                    <h1 class="uppercase text-2xl font-semibold">
                        ที่เสียบ USB
                    </h1>
                    <p class="capitalize text-stone-500">
                        ชาร์ตแบตมือถือได้
                    </p>
                </div>
                <img src="@/assets/services/charging.png" alt="charging" class="-z-50 md:mb-4 md:order-1">
            </div>
            <!-- Wedding service end -->
        </div>
        <!-- Services end -->

        <!-- Circle start -->
        <div class="bg-neutral-300 h-64 w-64 rounded-full absolute top-0 -right-20 mt-16 -z-20"></div>
        <!-- Circle end -->
    </section>
    <!-- Services section end -->
</template>

<script>
export default {

}
</script>

<style></style>