<template>
    <!-- Large Modal -->
    <div class="fixed top-0 left-0 right-0 z-50 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div class="bg-white p-4 rounded-lg shadow-lg relative">
            <button @click="close" class="absolute top-2 right-2 text-gray-400 hover:text-gray-900">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
            </button>
            <figure class="max-w-lg">
                <img :src="photo.picture" :alt="photo.name" class="h-auto max-w-full rounded-lg">
                <figcaption class="mt-2 text-sm text-center text-gray-500 dark:text-gray-400">{{ photo.name }}</figcaption>
            </figure>
        </div>
    </div>
</template>

<script>
export default {
    props: ['photo'],
    methods: {
        close() {
            this.$emit('close'); // Emit event to close modal
        },
    },
};
</script>
