<!-- src/components/Loading.vue -->
<template>
  <div class="loading-container">
    <div class="text-center">
      <span class="loader">Load&nbsp;ng</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingComponent',
};
</script>

<style>
.loader {
  color: #3f3f3f;
  display: inline-block;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
  letter-spacing: 4px;
  box-sizing: border-box;
}

.loader::before {
  content: '';
  position: absolute;
  right: 68px;
  bottom: 10px;
  height: 24px;
  width: 7px;
  outline: 1px solid #3f3f3f;
  color: #FF3D00;
  box-sizing: border-box;
  animation: animloader1 1s linear infinite alternate;
}

.loader::after {
  content: '';
  width: 4px;
  height: 4px;
  background: #FF3D00;
  position: absolute;
  right: 70px;
  top: 8px;
  box-sizing: border-box;
  animation: animloader 1s ease-out infinite alternate;
}

.loading-container {
  display: flex;
  justify-content: center; /* จัดกลางแนวนอน */
  align-items: center; /* จัดกลางแนวตั้ง */
  height: 100vh; /* ความสูงเต็มหน้าจอ */
  position: fixed; /* ให้มันอยู่บนสุด */
  top: 0;
  left: 0;
  width: 100%; /* ความกว้างเต็ม */
  background-color: rgba(255, 255, 255, 0.8); /* สีพื้นหลังที่อ่อนเพื่อให้ดูนุ่มนวล */
  z-index: 999; /* อยู่เหนือเนื้อหาอื่น */
}

@keyframes animloader {
  0% {
    top: 8px;
    transform: rotate(0deg) scale(1);
  }
  100% {
    top: 0px;
    transform: rotate(180deg) scale(1.5);
  }
}

@keyframes animloader1 {
  0% {
    box-shadow: 0 0 inset;
  }
  100% {
    box-shadow: 0 -28px inset;
  }
}
</style>
