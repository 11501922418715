<template>
	<!-- Footer start -->
	<div class="bg-stone-700 w-full flex justify-center items-center md:justify-evenly flex-col md:flex-row gap-8 p-8">
		<!-- Overview start -->
		<div class="text-stone-100 text-center hidden md:flex flex-col">
			<p class="tracking-widest text-4xl font-semibold text-stone-100">รถตู้หนองคาย</p>
			<p class="tracking-widest text-2xl font-light text-stone-100">By ผู้หมู่ภูธร</p>
			<hr class="mt-5 mb-5" />
			<p class="tracking-widest font-light text-stone-100">
				ให้บริการรถตู้เช่า/เหมา ท่องเที่ยว สัมมนา ทัศนาจร รับ-ส่ง หน้าด่าน-สนามบิน
				<br />
				ในพื้นที่ภาคอีสานตอนบน จังหวัดหนองคาย บึงกาฬ อุดรธานี ขอนแก่น สกลนคร นครพนม และจังหวัดใกล้เคียง
			</p>
		</div>
		<div class="flex md:hidden justify-center items-center">
			<img src="@/assets/Logo.png" alt="">
		</div>
		<!-- Overview end -->

		<!-- Links start -->
		<div class="text-stone-100 flex flex-col justify-center items-start gap-8">
			<a href="https://line.me/ti/p/U7DEIOLwgZ" target="_blank">
				<font-awesome-icon :icon="['fab', 'line']" /> : sansab_krab
			</a>
			<a href="https://www.facebook.com/nongkhaivanbypumoo" target="_blank">
				<font-awesome-icon :icon="['fab', 'facebook']" /> : รถตู้หนองคายbyผู้หมู่ภูธร
			</a>
			<a href="tel:085-461-8478">
				<font-awesome-icon :icon="['fas', 'phone']" /> : 085-461-8478
			</a>
		</div>
		<!-- Links end -->
	</div>
	<div
		class="bg-stone-700 border-none w-full flex justify-center items-center md:justify-evenly flex-col md:flex-row gap-8 p-8">
		<small class="tracking-widest font-light text-stone-100">Copyright &copy;2024, <a
				href="https://www.facebook.com/thinna.tj" target="_blank">CastCat-Service.</a> </small>
	</div>
	<!-- Footer end -->

</template>

<script>
export default {

}
</script>

<style></style>