<script setup>
import { RouterView } from 'vue-router';
import NavbarElement from '@/components/NavbarElement.vue';
import FooterElement from '@/components/FooterElement.vue';

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}
</script>

<template>
    <!-- Navbar start -->
    <navbar-element></navbar-element>
    <!-- Navbar end -->

    <!-- Rendered view start -->
    <RouterView />
    <!-- Rendered view end -->

    <!-- Footer start -->
    <footer-element></footer-element>
    <!-- Footer end -->

    <button @click="scrollToTop" class="scroll-to-top">
        <font-awesome-icon :icon="['fas', 'chevron-up']" /> <!-- ไอคอนลูกศรขึ้น -->
    </button>
</template>

<style scoped>
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #459BBD;
    color: white;
    border: none;
    width: 50px; /* ขนาดปุ่ม */
    height: 50px; /* ขนาดปุ่ม */
    border-radius: 50%; /* ทำให้เป็นวงกลม */
    display: flex; /* จัดแนวไอคอน */
    justify-content: center; /* จัดตำแหน่งกลาง */
    align-items: center; /* จัดตำแหน่งกลาง */
    cursor: pointer;
    font-size: 24px; /* ขนาดฟอนต์ */
    z-index: 1000;
    transition: background-color 0.3s; /* เพิ่มการเปลี่ยนแปลง */
}

.scroll-to-top:hover {
    background-color: rgb(118, 157, 235); /* สีเมื่อชี้เมาส์ */
}

.scroll-to-top:active {
    transform: scale(0.95); /* ทำให้ปุ่มเล็กลงเมื่อกด */
}
</style>
